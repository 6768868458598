import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Créez un composant React pour le bulletin de notes
const SchoolReport = ({ data }) => {
  console.log(data, 'data');
  // Rendu du PDF
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>School Report</Text>
          {/* Affichez les données fictives ici */}
          {data.map((item, index) => (
            <View key={index} style={styles.item}>
              <Text>{item.subject}</Text>
              <Text>{item.grade}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

// Styles pour le document PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 24,
    marginBottom: 20
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  }
});

// Fonction pour générer le PDF
const generatePDF = (data) => {
  const pdfDocument = (
    <SchoolReport data={data} />
  );

  const pdfContainer = document.createElement('div');
  pdfContainer.setAttribute('id', 'pdf-container');
  document.body.appendChild(pdfContainer);

  // Convertir le composant React en HTML
  html2canvas(pdfContainer).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    console.log(imgData);
    const pdf = new jsPDF('p', 'mm', 'a4');
    console.log(pdf);
    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
    console.log(pdf);
    pdf.save('bulletin_de_notes.pdf');
    console.log(pdf);
    document.body.removeChild(pdfContainer);
  });

  const container = document.getElementById('pdf-container');
  const root = createRoot(container);
  root.render(pdfDocument);


  // ReactDOM.render(pdfDocument, document.getElementById('pdf-container'));
};

// Composant React pour le bouton de déclenchement
const GeneratePDFButton = ({ data }) => {
  const handleGeneratePDF = () => {
    generatePDF(data);
  };

  return (
    <button onClick={handleGeneratePDF}>Générer PDF</button>
  );
};

// Exemple de données fictives pour le bulletin de notes
const mockData = [
  { subject: 'Mathématiques', grade: 'A' },
  { subject: 'Science', grade: 'B' },
  { subject: 'Histoire', grade: 'C' },
  { subject: 'Anglais', grade: 'B' },
  { subject: 'Géographie', grade: 'A' },
  { subject: 'Informatique', grade: 'A-' },
  { subject: 'Arts', grade: 'C+' },
  { subject: 'Éducation physique', grade: 'B-' },
  { subject: 'Musique', grade: 'A' },
  { subject: 'Langue étrangère', grade: 'B+' },
  // Ajoutez d'autres données fictives ici
];

// Composant principal
const App = () => {
  return (
    <div>
      <GeneratePDFButton data={mockData} />
    </div>
  );
};

export default App;
