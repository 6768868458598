import React from 'react';
import student1Image from './../../../images/avatar/avatar/Yanke.jpeg'; // Import de l'image de l'étudiant 1
import student2Image from './../../../images/avatar/avatar/Dady.jpeg'; // Import de l'image de l'étudiant 2

const StudentReportCard = () => {
  // Données fictives des élèves
  const students = [
    {
      name: 'Andrea Biligha',
      dateOfBirth: '2005-06-15',
      class: 'Grade 10',
      subjects: ['Math', 'Science', 'History', 'English', 'Art'],
      coefficients: [2, 3, 1, 2, 1],
      grades: [85, 90, 75, 80, 95], // Notes sur 100
      image: student1Image,
    },
    {
      name: 'John Smith',
      dateOfBirth: '2006-03-20',
      class: 'Grade 11',
      subjects: ['Math', 'Science', 'History', 'English', 'Art'],
      coefficients: [2, 3, 1, 2, 1],
      grades: [90, 85, 80, 95, 85], // Notes sur 100
      image: student2Image,
    },
  ];

  // Fonction pour calculer la moyenne
  const calculateAverage = (grades, coefficients) => {
    const totalPoints = grades.reduce((acc, grade, index) => acc + (grade * coefficients[index]), 0);
    const totalCoefficients = coefficients.reduce((acc, coefficient) => acc + coefficient, 0);
    return totalPoints / totalCoefficients;
  };

  // Fonction pour télécharger le PDF
  const handleDownloadPDF = () => {
    // Logique de téléchargement PDF
    console.log('PDF downloaded');
  };

  return (
    <div style={styles.container}>
      {students.map((student, index) => (
        <div key={index} style={styles.reportCard}>
          <h2 style={styles.heading}>Student Information</h2>
          <div style={styles.studentInfo}>
            <img src={student.image} alt={`Student ${index + 1}`} style={styles.studentImage} />
            <div>
              <p><strong>Name:</strong> {student.name}</p>
              <p><strong>Date of Birth:</strong> {student.dateOfBirth}</p>
              <p><strong>Class:</strong> {student.class}</p>
            </div>
          </div>

          <h2 style={styles.heading}>Grades</h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Subject</th>
                <th style={styles.tableHeader}>Coefficient</th>
                <th style={styles.tableHeader}>Grade</th>
              </tr>
            </thead>
            <tbody>
              {student.subjects.map((subject, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{subject}</td>
                  <td style={styles.tableCell}>{student.coefficients[index]}</td>
                  <td style={styles.tableCell}>{student.grades[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h2 style={styles.heading}>Average Grade</h2>
          <p style={styles.averageGrade}>The average grade of the student is: {calculateAverage(student.grades, student.coefficients).toFixed(2)}</p>

          <button onClick={handleDownloadPDF} style={styles.downloadBtn}>Download PDF</button>
        </div>
      ))}
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  reportCard: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '300px',
    margin: '20px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
  },
  heading: {
    marginBottom: '10px',
  },
  studentInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  studentImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginRight: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  averageGrade: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  downloadBtn: {
    backgroundColor: '#4caf50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default StudentReportCard;
